import React, { useRef } from "react"
import styled from "styled-components"
import ProductCard from "../PLP/ProductCard"
import breakpoints from "../../styles/breakpoints"
import { getProductLink } from "../../services/product/link"
import Slider from "react-slick/lib"
import colors from "src/styles/colors"


const ContentContainer = styled.div`
  padding-top: 0px;
  max-width: ${breakpoints.max};
  margin: auto;

  @media (max-width: ${breakpoints.md}) {
    ${'' /* #productCard1 {
      display: none;
    }
    #productCard2 {
      display: none;
    } */}
  }
`

const Arrow = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${(props) => (props.next ? "right: 10px" : "left: 10px")};
  cursor: pointer;

  ::after {
   
    content: "";
    display: block;
    width: 2vw;
    height: 2vw;
    border-style: solid;
    border-color: #000;
    border-width: 1px 1px 0 0;
    margin: calc((calc(2vw + 20px) - 2vw) / 2);
    transform: ${(props) => (props.next ? "rotate(45deg)" : "rotate(225deg)")};
  }
`

const StyledSlider = styled(Slider)`
  .slick-dots {
    position: relative;
    bottom: 0px;
    li {
      width: 50px;
      height: 50px;
      @media (max-width: ${breakpoints.md}) {
      width: auto;
    
    }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .slick-arrow {
    ::before {
      font-size: calc(2.4vw + 1rem);
      @media (min-width: 1600px){
    font-size: 55px;
  }
    color: ${colors.royalBlue};
      
  }

  }
  .slick-prev { 
    left: calc( (2.4vw + 1rem) * -1);
    @media (min-width: 1600px){
    left: -55px;
  }
    @media (max-width: ${breakpoints.md}) {
      
      left: auto;
    }
  }
  .slick-next { 
    ::before {
      font-size: calc(2.4vw + 1rem);
      @media (min-width: 1600px){
    font-size: 55px;
  }
    }
    @media (max-width: ${breakpoints.md}) {
      right: 0px;
    
    }
  }
`
const StyledGrid = styled.div`
  padding: 0px 6vw;
  @media (min-width: 1600px)
  {
    padding: 0px 96px;;
  }
${'' /* 

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(1, 1fr);
    grid-column-gap: 7vw;
    grid-row-gap: 10vw;
  }

  @media (min-width: ${breakpoints.md}) {
    padding: 0px 6vw;
    grid-template-columns: repeat(3, 1fr);

    display: grid;
    gap: 30px;
  } */}
`

const ProductLandingPageBlock = (props) => {


  const vegetarianIcon = props.data.contentfulShopPage.vegetarianIcon
  const glutenIcon = props.data.contentfulShopPage.glutenIcon

  let products = props.data.allSwellProduct.edges
  let contentfulProducts = props.data.contentfulPage.homePageShop.products
  let productMap = {}
// Iterate over contentfulProducts and extract relevant data
for (const contentfulProduct of contentfulProducts) {
  let newObj = {};
  newObj.title = contentfulProduct.title;
  newObj.hasVegetarian = contentfulProduct.hasVegetarian || false;
  newObj.isGlutenFree = contentfulProduct.isGlutenFree || false;

  // Get restaurant name from blocks
  const restaurantBlock = contentfulProduct.blocks?.find(
    (x) => x.__typename === "ContentfulRestaurant"
  );
  newObj.restaurant = restaurantBlock ? restaurantBlock.name : "";

  newObj.servingSize = contentfulProduct.servingSize || 2.5;
  newObj.newRelease = contentfulProduct.newRelease || false;
  newObj.spicy = contentfulProduct.spicy || 0;

  productMap[contentfulProduct.slug] = newObj;
}

// Map products to a new array with additional properties
products = products.map((x) => {
  const productData = productMap[x.node.slug] || {};
  return {
    node: {
      ...x.node,
      spicy: productData.spicy || 0,
      newRelease: productData.newRelease || false,
      servingSize: productData.servingSize || 2.5,
      restaurant: productData.restaurant || "",
      isGlutenFree: productData.isGlutenFree || false,
      hasVegetarian: productData.hasVegetarian || false,
      contentfulTitle: productData.title || x.node.name,
    },
  };
});


  const sliderRef = useRef()
  const settings = {
    arrows: true,
    dots: true,
    slidesPerRow: 3,
    dotsClass: "slick-dots",
    // customPaging: function (i) {
    //   return (
    //     <a>
    //     <img src={"http://clipart-library.com/img1/1036351.png"} />
    //       {/* <img src={images[i].file.url} alt={alt} /> */}
    //     </a>
    //   )
    // },
    responsive: [
      {
        breakpoint: 768,
        settings: {
          dots: true,
          slidesPerRow: 2,
        },
       
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesPerRow: 1,
        }
      }
    ],
  }

  const changeSlide = (value) => {
    if (value === -1) {
      sliderRef.current?.slickPrev()
    }
    if (value === 1) {
      sliderRef.current?.slickNext()
    }
  }
  return (
    <ContentContainer id={"ContentContainer"}>
      {/* {collection && <Header {...collection} />} */}
  
      <StyledGrid>
      <StyledSlider {...settings} >
        {products.map((product, index) => {
          const {
            images,
            name,
            price,
            sale_price,
            sale,
            slug,
            currency,
            stock_level,
            stock_status,
            options,
            contentfulTitle,
            hasVegetarian,
            isGlutenFree,
            restaurant,
            servingSize,
            newRelease,
            spicy,
          } = product.node
          return (
            
            <div id={"productCard" + index}>
              <ProductCard
                gatsbyImageData={
                  images[0].fileLocal?.childImageSharp.gatsbyImageData
                }
                gatsbyImageDataAlternate={
                  images[1]?.fileLocal?.childImageSharp.gatsbyImageData
                }
                price={price}
                sale_price={sale_price}
                sale={sale}
                name={contentfulTitle} //{name}
                path={getProductLink(slug)}
                currency={currency}
                stock_status={stock_status}
                stock_level={stock_level}
                hasVegetarian={hasVegetarian}
                isGlutenFree={isGlutenFree}
                restaurant={restaurant}
                options={options}
                servingSize={servingSize}
                newRelease={newRelease}
                spicy={spicy}
                vegetarianIcon={vegetarianIcon}
                glutenIcon={glutenIcon}
                homepage={true}
                key={index}
              />
            </div>
          )
        })}
        </StyledSlider>
      </StyledGrid>
      {/* <Arrow onClick={() => changeSlide(-1)} />
      <Arrow onClick={() => changeSlide(1)} next /> */}
    </ContentContainer>
  )
}

export default ProductLandingPageBlock
 
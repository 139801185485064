import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Image from "../Blocks/Image"
import { Link } from "gatsby"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import {
  getLocalisedPrice,
  checkMultiplePrice,
  getGiftCardStartingPrice,
} from "src/services/product/price"

const ProductLinkOverlay = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`
const VegToolTip = styled.div`
    border-radius: 10px;
    position: absolute;
    bottom: 31px;
    width: 100px;
    background-color: #f1d592;
    z-index: 5;
    right: 3px;
    font-size: 15px;
    color: white;
`

const GluteToolTip = styled.div`
   border-radius: 10px;
    position: absolute;
    bottom: 31px;
    width: 100px;
    background-color: #f1d592;
    z-index: 5;
    right: 3px;
    font-size: 15px;
    color: white;
`

const PerServing = styled.span`
  @media (min-width: 900px) {
    font-size: 0.8rem;
    vertical-align: bottom;
    ${"" /* line-height: 100%; */}
  }
  font-size: 9px;
  font-weight: normal;
`

const CardContainer = styled.div`
  overflow: hidden;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  display: inline-block;
  position: relative;
  border-color: ${colors.royalBlue};
  border-width: 2px;
  border-style: solid;
  border-radius: 15px;
  ${
    "" /* width: ${(props) => (props.homepage ? `25%` : `auto`)};
  margin-left: ${(props) => (props.homepage ? `10%` : `auto`)}; */
  }
  margin: ${(props) => (props.homepage ? `10%` : `auto`)};
`

const ProductDetail = styled.div`
  margin-top: 10px;
  text-align: center;
  @media (min-width: ${breakpoints.lg}) {
    margin-top: auto;
  }
`

const BottomRowContent = styled.div`
  @media (max-width: 1600px) and (min-width: ${breakpoints.md}) {
    margin-top: 12px;
  }
  @media (min-width: 1601px) {
    margin-top: 13px;
  }
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
`
const BottomRowContent2 = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  display: flex;
`

const Restaurant = styled.h4`
  color: ${colors.lightBlue};
  line-height: 1em;
  font-size: 0.8em;
  text-transform: uppercase;
  padding-top: 0.6em;
  margin-bottom: 0.6em;

  @media (min-width: ${breakpoints.lg}) {
    padding-top: 0.3em;
    margin-bottom: 0.3em;
    margin-right: 0.3vw;
    margin-left: 0.3vw;
    font-size: calc(0.6vw + 0.5rem);
  }
`

const Title = styled.h3`
  color: ${colors.royalBlue};
  line-height: 1em;
  font-size: 0.9em;
  padding-left: 0.1em;
  padding-right: 0.1em;
  padding-bottom: 0.6em;

  @media (min-width: ${breakpoints.lg}) {
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    font-size: calc(0.8vw + 0.5rem);
  }
  @media (max-width: ${breakpoints.md}) {
    font-size: 0.8em;
  }
`

const ProductTitle = styled.div`
  height: 4.6em;

  @media (min-width: ${breakpoints.lg}) {
    height: calc(5vw + 0.5em);
  }
  @media (max-width: ${breakpoints.md}) {
    height: 5em;
  }
  padding-bottom: 1em;
  border-bottom: solid ${colors.royalBlue} 2px;
`

const SafariDiv = styled.div`
  -webkit-border-top-left-radius: 15px;
  -webkit-border-top-right-radius: 15px;
  overflow: hidden;
  ${"" /* -khtml-border-radius: 15px; */}

  display: inline-block;
  position: relative;
  ${"" /* border-radius: 15px; */}
`
const ProductPrice = styled.div`
  height: 2em;
  color: ${colors.royalBlue};
`

const PriceDietBox = styled.div`
  display: flex;
  height: 100%;

  color: ${colors.royalBlue};
`

const DietContainer = styled.div`
  width: 25%;
  display: flex;
  justify-content: center;
`

const PriceContainer = styled.div`
  border-right: solid ${colors.royalBlue} 2px;
  width: 75%;
  color: ${colors.royalBlue};
`
const Price = styled.p`
  @media (min-width: 1600px) {
    ${"" /* line-height: 100%; */}
    font-size: 1rem;
  }
  @media (max-width: ${breakpoints.md}) {
    ${"" /* line-height: 100%; */}
    font-size: .7rem;
  }

  font-size: 0.9rem;
  line-height: 0.8em;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
  font-weight: bold;
  color: ${colors.royalBlue};
`
const PriceImg = styled.div`
  display: flex;

  position: relative;
  margin-left: 3px;
  bottom: 3px;
  right: 3px;
`

const Banner1 = styled.div`
  position: absolute;
  width: 100px;
  height: 25px;
  right: 10px;
  top: 10px;
  background: white;
  z-index: 1;
  border-radius: 8px;
  ${fonts.workSansBold};
  color: ${colors.royalBlue};
  opacity: 0.8;
`

const Banner2 = styled.div`
  position: absolute;
  ${'' /* width: 100px; */}
  padding-left: 5px;
  padding-right: 5px;
  height: 25px;
  right: 10px;
  top: 50px;
  background: white;
  z-index: 1;
  border-radius: 8px;
  ${fonts.workSansBold};
  color: ${colors.royalBlue};
  opacity: 0.8;
`

const Diet = styled.span``

const SoldOut = styled.div`
  ${fonts.workSansBold};
  color: ${colors.royalBlue};
  line-height: 0.8em;
  padding-top: 7px;
  font-size: 0.8em;
  text-transform: uppercase;
  text-align: center;
`

const NewRelease = styled.div`
  ${fonts.workSans};
  color: ${colors.black};
  line-height: 0.8em;
  padding-top: 7px;
  font-size: 0.7em;
  text-align: center;
`

const Spicy = styled.div`
  ${fonts.workSans};
  color: ${colors.black};
  line-height: 0.8em;
  padding-top: 7px;
  font-size: 0.7em;
  text-align: right;
  padding-right: 6px;
`

const ProductCard = (props) => {
  const {
    price,
    sale_price,
    sale,
    name,
    gatsbyImageData,
    gatsbyImageDataAlternate,
    path,
    currency,
    stock_status,
    hasVegetarian,
    restaurant,
    options,
    slug,
    servingSize,
    stock_level,
    newRelease,
    quickCook,
    spicy,
    isGlutenFree,
    glutenIcon,
    vegetarianIcon,
    homepage,
  } = props

  const [isHovered, setIsHovered] = useState(false)

  const [vegIsHovered, setVegIsHovered] = useState(false)

  const [gluteIsHovered, setGluteIsHovered] = useState(false)

  const isSoldOut = (stock_status && stock_status === "out_of_stock") || stock_level <= 0

  const isOnSale = !path.includes("gift-card") && sale

  const isNewRelease = newRelease ?? false

  const isQuickCook = quickCook ?? false

  const isLowStock = stock_level && stock_level < 10 && !isSoldOut

  const spiceLevel = spicy ?? 0

  const veg = hasVegetarian ?? false

  const glute = isGlutenFree ?? false

  const hasMultiplePrice = checkMultiplePrice(options)

  const productPrice = path.includes("gift-card")
    ? getGiftCardStartingPrice(options)
    : price

  function controlTheSpice(spiceLevel) {
    var strang = ""
    for (let i = 0; i < spiceLevel; i++) {
      strang += "🌶"
    }
    return strang + (spiceLevel === 3 ? " Spicy" : ( spiceLevel === 2 ? " Medium" : (spiceLevel === 1 ? " Mild" : "")) )
  }

  function setTempHover(elem) {
    if (elem === "veg") {
     setVegIsHovered(true)
     setTimeout(() => { setVegIsHovered(false) }, 3500);
    } else if (elem === "glute") {
      setGluteIsHovered(true)
      setTimeout(() => { setGluteIsHovered(false) }, 3500);
    }
  }
  const salePrice = path.includes("gift-card")
    ? getGiftCardStartingPrice(options)
    : sale_price
  return (
    <CardContainer
      homepage={homepage}
      onMouseOver={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isSoldOut ? (
        <Banner1>
          <NewRelease>Sold Out</NewRelease>
        </Banner1>
      ) : isLowStock ? (
        <Banner1>
          <NewRelease>Low Stock</NewRelease>
        </Banner1>
      ) : isNewRelease ? (
        <Banner1>
          <NewRelease>New Release</NewRelease>
        </Banner1>
      ) : ( isQuickCook ?         <Banner1><NewRelease>Quick Cook</NewRelease> </Banner1>:
        ""
      )}
      {(isSoldOut || isNewRelease || isLowStock  ) && spiceLevel > 0 || isQuickCook ? (
        isSoldOut && ( !spiceLevel || spiceLevel === 0) ? (
          <Banner2> <NewRelease>Sold Out </NewRelease></Banner2>
        ) : ( spiceLevel > 0 && !isSoldOut) ? (
          <Banner2>
            <Spicy>{controlTheSpice(spiceLevel)}</Spicy>
          </Banner2>
        ) : isLowStock  ? (
          <> {/* <Banner2>Low Stock</Banner2> */}</>
         
        ) :  ( isQuickCook && ( isNewRelease || isLowStock ) ? <Banner2 style={{width: "85px"}}><NewRelease>Quick Cook</NewRelease></Banner2> :
          ""
        )
      ) : spiceLevel > 0 ? (
        <Banner1 style={{ width: "auto", paddingLeft: ".2em" }}>
          <Spicy>{controlTheSpice(spiceLevel)}</Spicy>
        </Banner1>
      ) : (
        ""
      )}
      <SafariDiv>
        <Image
          topBorderRadius={"13px 13px"}
          loading={"lazy"}
          image={
            isHovered
              ? { gatsbyImageData: gatsbyImageDataAlternate, alt: "product" }
              : { gatsbyImageData: gatsbyImageData }
          }
          alt={name}
        />
      </SafariDiv>
      <ProductDetail>
        <ProductTitle>
          <Restaurant>{restaurant}</Restaurant>
          <Title>{name}</Title>
        </ProductTitle>
        <ProductLinkOverlay to={path} />
        <ProductPrice>
          <PriceDietBox>
            <PriceContainer>
              <BottomRowContent>
                {name == "Gift Card" ? (
                  <Price>Prices Vary</Price>
                ) : (
                  <Price>
                    {/* {hasMultiplePrice && "FROM "} */}
                    {isOnSale ? (
                      <span>
                        <span style={{ fontWeight: "bold" }}>
                          {getLocalisedPrice(salePrice, currency, false, 0)}
                        </span>
                        <span dangerouslySetInnerHTML={{ __html: "&nbsp;" }} />
                        <strike style={{ fontSize: ".8em" }}>
                          {getLocalisedPrice(productPrice, currency, false, 0)}
                        </strike>
                      </span>
                    ) : (
                      getLocalisedPrice(productPrice, currency, false, 0)
                    )}{" "}
                    {!props.addOn &&
                    <>
                    | {" "}
                    <span>
                      {" "}
                      $
                      {isOnSale
                        ? (salePrice / servingSize).toFixed(0)
                        : (productPrice / servingSize).toFixed(0)}{" "}
                      <PerServing> per serving</PerServing>{" "}
                    </span>
</>
                    }
                  </Price>
                )}
              </BottomRowContent>
            </PriceContainer>
            <DietContainer >
              <Diet>
                <BottomRowContent2>
              { vegIsHovered ? <VegToolTip>
                  Veg Option
                </VegToolTip> : ""}
                { gluteIsHovered ? <GluteToolTip>
                  Gluten Free
                </GluteToolTip> : ""}
                  {veg ? (
                    <PriceImg    onMouseOver={() => {
                
        setVegIsHovered(true)
      }}
      onMouseLeave={() => setVegIsHovered(false)}   
      onClick={() => setTempHover("veg")}>
                      {" "}
                      
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                        }}
                        src={vegetarianIcon.file.url}
                      />
                    </PriceImg>
                  ) : (
                    ""
                  )}
                  {glute ? (
                    <PriceImg  onMouseOver={() => {
                
                setGluteIsHovered(true)
              }}
              onMouseLeave={() => setGluteIsHovered(false)} 
              onClick={() => setTempHover("glute")}  >
                      {" "}
                      <img
                        style={{
                          width: "15px",
                          height: "15px",
                        }}
                        src={glutenIcon.file.url}
                      />
                    </PriceImg>
                  ) : (
                    ""
                  )}
                </BottomRowContent2>
              </Diet>
            </DietContainer>
          </PriceDietBox>

       
        </ProductPrice>
      </ProductDetail>
    </CardContainer>
  )
}

export default ProductCard
